import * as React from "react"
import { Link } from "gatsby"

// Comonent library
import 'semantic-ui-css/semantic.min.css' // Semantic UI component library: https://react.semantic-ui.com/
//// see https://react.semantic-ui.com/layouts/fixed-menu/ 
//// and https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/src/layouts/FixedMenuLayout.js for example
import { 
  Container,
  Header,
  Image,
} from 'semantic-ui-react'

import HeaderBar from '../components/HeaderBar.js'
import FooterBar from '../components/FooterBar.js'

// styles



// data



// markup
const IndexPage = () => {
  // NoteL add 'inverted' to Semantic elements for dark-mode palette on that element
  // Example <Menu fixed='top' inverted>...</Menu>
  return (
  <div>
  <header role="banner">
      <HeaderBar />
    </header>
    <main role="main">

    <Container text style={{ marginTop: '7em' }}>

<div>
<Header as='h1'>Welcome to Plantabyte.net!</Header>
<Header as='h3'>Home of the Digital Plant Biologist</Header>

<table border="0" style={{borderSpacing: '12px'}}><tr><td>
<p>I'm Dr. Christopher C. Hall. I have a Phd in plant biology and over a decade of software and hardware engineering experience developing high-precision scientific instruments for measuring physiological stress in plants and microalgae. Such instruments include the <Link to='https://prl.natsci.msu.edu/research-tech/center-for-advanced-algal-and-plant-phenotyping/depi-chambers/'>dynamic environment photosynthesis imager (DEPI)</Link>, the <Link to='https://www.phenometricsinc.com/all-products/pbr102-f/'>environmental simulation photobioreactor (ePBR)</Link>, and the <Link to='https://techlab.uts.edu.au/lab/industry-4-0-testlab/'>Industry-4.0 Algae Test Lab</Link>. 
</p></td><td width="256">
<Image src='/images/chrishall/Christopher-C-Hall.jpg' alt='Dr. Christopher Hall measuring plants with scientific instruments.' size='medium' circular />
</td></tr></table>
</div>

<div><table border="0" style={{borderSpacing: '12px'}}><tr><td width="150">
<Image src='/images/science/ePBRs.jpg' alt='Phenometrics ePBR array' size='small' rounded /></td><td>
<Header as='h2'>Science</Header>
<p>Science is the best process for answering questions. As a professional 
  scientist, I have spent my whole life asking questions, and then finding 
  the answers to those questions. I share some of the things I’ve learned 
  on this website, but you can also find me answering questions about 
  science on <Link to='https://www.quora.com/profile/Chris-Hall-201'>Quora</Link>,{' '}
  <Link to='https://twitter.com/DrCHall'>Twitter</Link>, and{' '}
  <Link to='https://www.linkedin.com/in/christopher-hall-b00b44110/'>LinkedIn</Link>.
  </p>
</td></tr></table>
</div>

<div><table border="0" style={{borderSpacing: '12px'}}><tr><td width="150">
<Image src='/images/CCHall-Electronics-Test.jpg' alt='Box of LED drivers' size='small' rounded /></td><td>
<Header as='h2'>Technology</Header>
<p>I write code and design automation systems in both for my day job and as a hobby. I'm, proficient in many programming languages, including Java, Python, Rust, C, JavaScript, and several more. I even made this web-site from scratch using Gatsby. You can follow my public code projects on my <Link to='https://github.com/DrPlantabyte/'>GitHub</Link> page. </p>
</td></tr></table>
</div>

<div><table border="0" style={{borderSpacing: '12px'}}><tr><td width="150">
<Image src='/images/art/Limark_sphere-view.png' alt='One of my fictional worlds' size='small' rounded /></td><td>
<Header as='h2'>Creativity</Header>
<p>I am a world-builder at heart. I love to create imaginary worlds, ranging from whimsical fantasies to plausible exoplanets, and then tell stories set in these worlds through tabletop role-playing games (TTRPGs) such as Dungeons & Dragons. Playing and dungeon-mastering TTRPGs is my favorite hobby and outlet for my creativity. I support my TTRPG games with some of my own art (and software), like this map I made of a fantasy world I use in my D&D games.</p>
</td></tr></table></div>

    </Container>

    </main>
    <footer role="banner">
      <FooterBar />
    </footer>
    </div>
  )
}

export default IndexPage
